import React, { FC, useMemo, useState } from 'react'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IProduct } from '../../../types/product'
import { NoData } from '../../NoData'
import ProductCard from '../../ProductCard/ProductCard'
import style from '../Styles/index.module.scss'
import { getOtherProducts } from '../../../utils/filters'

interface IProductsOthers {
    productsList: IProduct[]
    hideInactive: boolean
}

export const ProductsOthers: FC<IProductsOthers> = ({
  productsList,
  hideInactive,
}) => {
  const { productsGroups } = useTypedSelector((state) => state.products)
  const [open, setOpen] = useState(true)
  const [render, setRender] = useState(false)
  const groupId = 'others-products-group'
  const openCloseHandle = () => setOpen(!open)
  const othersProductsList = getOtherProducts(productsGroups, productsList)

  useMemo(() => {
    const raw = localStorage.getItem(groupId) || 'true'
    setOpen(JSON.parse(raw))
    setRender(true)
  }, []) // eslint-disable-line

  useMemo(() => {
    localStorage.setItem(groupId, JSON.stringify(open))
  }, [open]) // eslint-disable-line

  useMemo(() => {
    if (!productsGroups.length && render) {
      localStorage.setItem(groupId, JSON.stringify(true))
      setOpen(true)
    }
  }, [productsGroups]) // eslint-disable-line

  return (
    <>
      {!othersProductsList.length && !hideInactive ? (
        <></>
      ) : (
        <>
          <div className={style.groupOthers}>
            {productsGroups.length ? (
              <div
                className={style.header}
                aria-controls="collapse-card"
                aria-expanded={open}
              >
                <div onClick={openCloseHandle} className={style.container}>
                  <h4 className={style.title}>Other products</h4>
                  <div className="ms-4">
                    <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            <Collapse in={open}>
              <div id="collapse-card">
                {!othersProductsList.length && hideInactive ? (
                  <NoData
                    style={{ height: '150px' }}
                    messageId="summary.noActiveProductsInGroup"
                  />
                ) : (
                  <div className="d-flex flex-wrap px-3">
                    {othersProductsList.map((product: IProduct) => (
                      <ProductCard product={product} key={product.guid} />
                    ))}
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </>
      )}
    </>
  )
}
