import {
  integerInput,
  textInput,
  passwordInput,
  selectInput,
  checkboxInput,
  doubleInput,
} from '../inputs/controls'
import { getEmptyData } from '../../utils/InitData'
import { inputValidationCheck } from '../../validators/inputValidators/inputValidators'
import { numberInputErrors } from '../../utils/validationErrors/numberInputErrors'
import { stringInputErrors } from '../../utils/validationErrors/stringInputErrors'
import { secretInputErrors } from '../../utils/validationErrors/secretInputErrors'
import { ModelTypes } from './types'

interface IInputCall {
  type: string
  name: string
  isLabelHidden: boolean
  schema: any
  data?: any
  secretSchema?: any
  state?: any
  isFeedbackFixed?: boolean
  className?: string
}

export function inputCall(params: IInputCall) {
  const {
    type,
    name,
    isLabelHidden,
    data,
    schema,
    secretSchema,
    state,
    isFeedbackFixed,
    className,
  } = params

  switch (type) {
    case ModelTypes.INTEGER:
      return integerInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: numberInputErrors(state?.[name], schema),
        validationType: data?.validationType,
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        className,
      })
    case ModelTypes.STRING:
      return textInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: stringInputErrors(state?.[name], schema),
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        className,
      })
    case ModelTypes.SECRET:
      return passwordInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: secretInputErrors(state?.[name], schema),
        validationSchema: inputValidationCheck(
          secretSchema?.type,
          secretSchema
        ),
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        className,
      })
    case ModelTypes.DOUBLE:
      return doubleInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: numberInputErrors(state?.[name], schema),
        validationType: data?.validationType,
        precision: schema.precision || 2,
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        className,
      })
    case ModelTypes.ENUM:
      return selectInput({
        name,
        options: Object.keys(schema?.options || {}),
        selected:
          data?.selected || schema?.default || Object.keys(schema?.options)[0],
        isColumn: data?.isColumn,
        className,
      })
    case ModelTypes.FLAG:
      return checkboxInput(
        name,
        data?.tooltipText,
        data?.label,
        '',
        false,
        'switch'
      )
    default:
      return textInput({
        name,
        isLabelHidden,
        isColumn: data?.isColumn,
        tooltipText: data?.tooltipText,
        label: data?.label,
        errMsg: stringInputErrors(state?.[name], schema),
        isRequired: schema?.isRequired,
        isFeedbackFixed,
        className,
      })
  }
}

export const createEmptyDocument = (schema: any, name: string, data?: any) => {
  let init: any = data || {}

  if (schema?.children) {
    const keys = Object.keys(schema?.children || {})
    for (const item in keys) {
      const element = getEmptyData(schema?.children[keys[item]])
      init = { ...init, [keys[item]]: element }
    }
  }
  return init
}
