export enum LogsActions {
    PREPARE_LOGS = 'PREPARE_LOGS',
    PREPARE_LOGS_SUCCESS = 'PREPARE_LOGS_SUCCESS',
    PREPARE_LOGS_ERROR = 'PREPARE_LOGS_ERROR',
    DOWNLOAD_LOG = 'DOWNLOAD_LOG',
    DOWNLOAD_LOG_SUCCESS = 'DOWNLOAD_LOG_SUCCESS',
    DOWNLOAD_LOG_ERROR = 'DOWNLOAD_LOG_ERROR',
    FETCH_LOGS_LIST = 'FETCH_LOGS_LIST',
    FETCH_LOGS_LIST_SUCCESS = 'FETCH_LOGS_LIST_SUCCESS',
    FETCH_LOGS_LIST_ERROR = 'FETCH_LOGS_LIST_ERROR',
}

export interface ILogFile {
    from: string
    to: string
    log_id: string
    product_id?: string
    status?: string
    error?: string
    issue_time?: string | number
}

export interface ILogsReducer {
    logs: ILogFile[]
}