import React, { FC, useEffect } from 'react'
import { buildControlsExt, multiSelectInput } from '../../../inputs/controls'
import {
    getMaxRange,
    getMinRange,
    getRange,
    getValidEndDate,
    periodOptions,
} from '../utils'
import DatePicker from 'react-datepicker'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { IBody } from '../types'
import '../styles/index.module.scss'

export const Body: FC<IBody> = ({ logsState, setLogsState }) => {
    const period = buildControlsExt(
        [
            multiSelectInput({
                name: 'period',
                isMulti: false,
                isColumn: true,
                options: periodOptions,
                label: 'period',
                className: 'mb-1',
            }),
        ],
        logsState,
        setLogsState,
        ''
    )
    console.log('logsState>>', logsState)
    useEffect(() => {
        getRange(logsState, setLogsState)
    }, [logsState.period])

    const onChangeTo = (date: Date): void => {
        setLogsState((prev: typeof logsState) => ({ ...prev, to: date }))
    }

    const onChangeFrom = (date: Date): void => {
        const dateTo = getValidEndDate(date, logsState.to)
        setLogsState((prev: typeof logsState) => ({
            ...prev,
            to: dateTo,
            from: date,
        }))
    }

    return (
        <div className="blockquote word-break mt-2 pt-2">
            <div className="mt-3">{period}</div>
            <div className="d-flex align-items-center mt-3">
                <div
                    style={{ width: '30%' }}
                    className="label-main text-capital"
                >
                    <FormattedMessage id="modals.logs.chooseDate" />
                </div>
                <div
                    style={{ width: '70%' }}
                    className="p-0 d-flex justify-content-between"
                >
                    <Form.Group
                        style={{ maxWidth: '600px' }}
                        className={`logs-date-time d-flex flex-1 mt-1 mb-1 align-items-center `}
                    >
                        <DatePicker
                            className="form-control datePicker"
                            selected={logsState.from}
                            onChange={onChangeFrom}
                            dateFormat="yyyy-MM-dd"
                            maxDate={new Date()}
                            disabled={logsState?.period?.value !== 'CUSTOM'}
                        />
                    </Form.Group>
                    <Form.Group
                        style={{ maxWidth: '600px' }}
                        className={`logs-date-time ms-3 d-flex flex-1 mt-1 mb-1 align-items-center `}
                    >
                        <DatePicker
                            className="form-control datePicker"
                            selected={logsState.to}
                            onChange={onChangeTo}
                            dateFormat="yyyy-MM-dd"
                            minDate={getMinRange(logsState.from)}
                            maxDate={getMaxRange(logsState.from)}
                            disabled={logsState?.period?.value !== 'CUSTOM'}
                        />
                    </Form.Group>
                </div>
            </div>
        </div>
    )
}
