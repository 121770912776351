import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { IPasswordInput } from './types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PasswordInput: React.FC<IPasswordInput> = ({
  state,
  setState,
  touched,
  setTouched,
  errors,
  errMsg,
  name,
  className,
  placeholder,
  label,
  isDisabled,
  tooltipText,
  isColumn,
  isLabelHidden,
  isRequired,
  inputType,
  isFeedbackFixed,
}) => {
  const [type, setType] = useState('password')
  const [isChanged, setIsChanged] = useState(false)

  const showPassword = (): void => {
    setType('string')
  }

  const hidePassword = (): void => {
    setType('password')
  }

  const handleChange = (event: any): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
    setState({
      ...state,
      [name]: event.target.value,
    })
    setIsChanged(true)
  }

  const handleBlur = (): void => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const setSecretState = (): string => {
    if (inputType === 'secret') {
      return !isChanged ? '******' : state?.[name]
    } else {
      return state?.[name]
    }
  }

  const dataState = setSecretState()
  const getValidationResult = (): boolean =>
    touched && touched[name] && errors && errors[name]
  const isInvalid: boolean = getValidationResult()

  return (
    <Form.Group
      style={{ maxWidth: '600px' }}
      className={`${className} ${
        isInvalid && !isFeedbackFixed && 'mb-4'
      } d-flex flex-1 mw-250 mt-1 mb-1 align-items-center justify-content-between`}
    >
      {label && !isLabelHidden ? (
        <Form.Label
          style={{ maxWidth: isColumn ? '30%' : 'auto' }}
          className="label-main mt-1 text-capital pe-2"
        >
          <span className="label-main-wrapper p-relative">
            <FormattedMessage id={label} />:{' '}
            {isRequired && (
              <span className="app-color-text label-required-mark">*</span>
            )}
          </span>
        </Form.Label>
      ) : null}
      <span
        style={{ width: '70%' }}
        className={`d-flex align-items-center ${
          !isColumn ? 'flex-1' : ''
        } p-relative`}
      >
        <span className="d-flex align-items-center flex-column w-100">
          <Form.Control
            className={`input-main ${
              !tooltipText ? 'input-padding-sm' : 'password-input-padding'
            }`}
            type={type}
            placeholder={placeholder}
            value={dataState}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!!isDisabled}
            isInvalid={isInvalid}
            name={name}
          />
          <Form.Control.Feedback className="input-feedback" type="invalid">
            <FormattedMessage
              id={errMsg?.message || 'password-error'}
              values={errMsg?.values}
              tagName="span"
            />
          </Form.Control.Feedback>
        </span>
        <div className="input-tooltip d-flex align-items-center">
          <div
            onMouseDown={showPassword}
            onMouseLeave={hidePassword}
            onMouseUp={hidePassword}
          >
            <FontAwesomeIcon
              className="opacity-50"
              icon={type === 'password' ? faEyeSlash : faEye}
            />
          </div>
          {tooltipText && <PromptBtn prompt={tooltipText ?? ''} />}
        </div>
      </span>
    </Form.Group>
  )
}

export default PasswordInput
