import { Periods } from '../types'
import { v4 as uuid } from 'uuid'

export const getMinRange = (date: Date): Date => new Date(date)

export const getMaxRange = (date: Date): Date => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 13)
    const today = new Date()
    const resultDate = newDate.getTime() < today.getTime() ? newDate : today
    return resultDate
}

export const getValidEndDate = (dateFrom: Date, dateTo: Date): Date => {
    const newDateFrom = new Date(dateFrom)
    const actualDateTo = new Date(dateTo)
    const today = new Date()

    const twoWeeksFromNow = new Date(
        newDateFrom.setDate(newDateFrom.getDate() + 13)
    )

    const potentialDate =
        twoWeeksFromNow.getTime() < today.getTime() ? twoWeeksFromNow : today

    const newDateTo =
        actualDateTo.getTime() < potentialDate.getTime() &&
        actualDateTo.getTime() >= dateFrom.getTime()
            ? actualDateTo
            : potentialDate

    return newDateTo
}

export const periodOptions = [
    { label: 'Custom', value: Periods.CUSTOM },
    { label: 'Today', value: Periods.TODAY },
    { label: 'Last 3 days', value: Periods.THREE_DAYS },
    { label: 'Last week', value: Periods.WEEK },
    { label: 'Last 2 weeks', value: Periods.TWO_WEEKS },
]

export enum LogsStatus {
    ACCEPTED = 'Accepted',
    EXECUTED = 'Executed',
    DECLINED = 'Declined',
}

export const getPeriod = (
    state: any,
    setLogsState: any,
    from: Date,
    to: Date
) => {
    const startDate = new Date(from)
    const endDate = new Date(to)
    const timeDiff = Math.abs(startDate.getTime() - endDate.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

    switch (diffDays) {
        case 1:
            setLogsState({
                ...state,
                from,
                to,
                period: { label: 'Today', value: Periods.TODAY },
            })
            break

        case 4:
            setLogsState({
                ...state,
                from,
                to,
                period: { label: 'Last 3 days', value: Periods.THREE_DAYS },
            })
            break

        case 8:
            setLogsState({
                ...state,
                from,
                to,
                period: { label: 'Last week', value: Periods.WEEK },
            })
            break

        case 15:
            setLogsState({
                ...state,
                from,
                to,
                period: { label: 'Last 2 weeks', value: Periods.TWO_WEEKS },
            })
            break

        default:
            setLogsState({
                ...state,
                from,
                to,
                period: { label: 'Custom', value: Periods.CUSTOM },
            })
    }
}

export const getRange = (state: any, setLogsState: any) => {
    const date = new Date()

    switch (state?.period?.value) {
        case Periods.TODAY:
            setLogsState({
                ...state,
                from: new Date(),
                to: new Date(),
            })
            break

        case Periods.THREE_DAYS:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 3)),
                to: new Date(),
            })
            break

        case Periods.WEEK:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 7)),
                to: new Date(),
            })
            break

        case Periods.TWO_WEEKS:
            setLogsState({
                ...state,
                from: new Date(date?.setDate(date?.getDate() - 14)),
                to: new Date(),
            })
            break

        default:
            setLogsState({ ...state })
            break
    }
}

export interface IPeriod {
    from: string
    to: string
}

export class Period {
    period: IPeriod

    constructor(from: string, to: string) {
        this.period = { from: from, to: to }
    }
}

export const convertDatesToSendFormat = (dates: {
    from: Date
    to: Date
}): Period => {
    const from = dates.from?.toISOString().slice(0, 10)
    const to = dates.to?.toISOString().slice(0, 10)

    return new Period(from, to)
}

export interface IRenderPeriod {
    log_id: string
    from: string
    to: string
}

export const convertDatesToRenderFormat = (dates: {
    from: Date
    to: Date
}): IRenderPeriod => {
    return {
        log_id: uuid(),
        from: dates?.from?.toLocaleDateString().slice(0, 10),
        to: dates?.to?.toLocaleDateString().slice(0, 10),
    }
}
