export const getNumberSchema = (inputString: any, schema: any) => {
  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be greater or equal',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) >= BigInt(schema.greater_or_equal)
        }
        return val >= schema.greater_or_equal
      }
    )
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be greater than',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) > BigInt(schema.greater_than)
        }
        return val > schema.greater_than
      }
    )
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test(
      'num',
      'Must be less or equal',
      (val: any) => {
        if (!schema.is_required && (val === 'null' || val === null)) {
          return true
        }
        if (val && val !== 'null' && Number.isInteger(val)) {
          return BigInt(val) <= BigInt(schema.less_or_equal)
        }
        return val <= schema.less_or_equal
      }
    )
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('num', 'Must be less than', (val: any) => {
      if (!schema.is_required && (val === 'null' || val === null)) {
        return true
      }
      if (val && val !== 'null' && Number.isInteger(val)) {
        return BigInt(val) < BigInt(schema.less_than)
      }
      return val < schema.less_than
    })
  }
  if (schema.is_required) {
    inputString = inputString.required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}
