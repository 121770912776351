import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useMemo, useState } from 'react'
import { Card, Collapse } from 'react-bootstrap'
import style from '../Styles/index.module.scss'
import { IProduct, IProductGroup } from '../../../types/product'
import ProductCard from '../../ProductCard/ProductCard'
import { NoData } from '../../NoData'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import AppButton from '../../buttons/AppButton/AppButton'
import { ModalTypes } from '../../../types/modals'
import { showModal } from '../../../store/actions/modalActions'
import { User, UserPermissions } from '../../../entity/User'
import {
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
} from 'react-beautiful-dnd'

interface IProductsGroups {
    productsList: IProduct[]
    groupData: IProductGroup
    hideInactive: boolean
    index?: any
}

export const ProductsGroups: FC<IProductsGroups> = ({
    productsList,
    groupData,
    hideInactive,
    index,
}) => {
    const dispatch = useAppDispatch()

    const [open, setOpen] = useState(true)

    const { group_id: groupId } = groupData

    const products = productsList.reduce(
        (acc: IProduct[], el: IProduct, i: number, arr) => {
            const item = arr.find((el) => el.guid === groupData?.entries[i])
            if (item) {
                acc[i] = item;
                return acc
            } else {
                return acc;
            }
        }, [])

    const user = new User()

    const openCloseHandle = () => setOpen(!open)

    const deleteGroupHandle = (): void => {
        dispatch(
            showModal(ModalTypes.DELETE_PRODUCTS_GROUP_MODAL, {
                groupId,
                products: products.map((product: IProduct) => product.guid),
            })
        )
    }

    useMemo(() => {
        const raw = localStorage.getItem(groupId) || 'true'
        setOpen(JSON.parse(raw))
    }, []) // eslint-disable-line

    useMemo(() => {
        localStorage.setItem(groupId, JSON.stringify(open))
    }, [open]) // eslint-disable-line

    return (
        <Draggable
            key={String(groupData.group_id)}
            draggableId={String(groupData.group_id)}
            index={index}
        >
            {(provided: DraggableProvided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <Card className={style.groupCard}>
                        <div
                            {...provided.dragHandleProps}
                            className={
                                open ? style.header : style.headerCollapsed
                            }
                        >
                            <div
                                className="w-100 d-flex align-items-center"
                                onClick={openCloseHandle}
                                aria-controls="collapse-card"
                                aria-expanded={open}
                            >
                                <h4 className={style.title}>
                                    {groupData?.name}
                                </h4>
                                <div className="ms-4">
                                    <FontAwesomeIcon
                                        icon={open ? faAngleUp : faAngleDown}
                                    />
                                </div>
                            </div>
                            {user.permissions.includes(
                                UserPermissions.GROUP_MANAGEMENT
                            ) && (
                                <AppButton
                                    className="app-color-group"
                                    onClick={deleteGroupHandle}
                                    variant={'delete'}
                                />
                            )}
                        </div>

                        <Droppable
                            droppableId={String(groupData.group_id)}
                            direction="horizontal"
                            type="product"
                        >
                            {(provided: DroppableProvided, snapshot) => (
                                <div className={style.flexContainer}
                                     style={{
                                         backgroundColor: snapshot.isDraggingOver
                                             ? "#8dabc4"
                                             : "",
                                     }}
                                >
                                    <Collapse in={open}>
                                        <div
                                            id="collapse-card"
                                            style={{ width: '100%' }}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}

                                        >
                                            {!products.length ? (
                                                <NoData
                                                    style={{ height: '300px'}}
                                                    messageId={
                                                        hideInactive
                                                            ? 'summary.noActiveProductsInGroup'
                                                            : 'summary.noProductsInGroup'
                                                    }
                                                />
                                            ) : (
                                                <div className={style.body}>
                                                    {products.map((product: IProduct, index: number) => (
                                                        <ProductCard
                                                            product={product}
                                                                groupId={groupId}
                                                                key={product.guid}
                                                                index={index}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </Collapse>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Card>
                </div>
            )}
        </Draggable>
    )
}
