import * as React from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useIntl } from 'react-intl'
import 'react-datepicker/dist/react-datepicker.css'
import { getLabel } from '../../validators'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { IDateTimePicker } from './types'
import { TimeFormats } from '../models/DateTime/types'
import moment from 'moment'

const DateTimePicker: React.FC<IDateTimePicker> = ({
    state,
    setState,
    valueKey,
    messageKey,
    schema,
    timeSchema,
    timeIntervals,
    className,
    description,
    isColumn,
    minDate,
    maxDate,
    disabled,
    isRequired,
    timeFormat,
    isLabelHidden,
}) => {
    const intl = useIntl()

    const onChange = (date: Date): void => {
        setState({ ...state, [valueKey]: date })
    }

    switch (timeFormat) {
        case TimeFormats.FULL_DATE_FULL_TIME:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 m-1 align-items-center p-relative`}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    Date.parse(state?.[valueKey])
                                        ? new Date(state[valueKey])
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="yyyy/MM/dd HH:mm:ss.SSS"
                                timeCaption="time"
                                dateFormat="yyyy/MM/dd HH:mm:ss.SSS"
                                timeFormat="HH:mm:ss"
                                showTimeSelect
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.DATE_HOURS_MINUTES_SECONDS:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center `}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    Date.parse(state?.[valueKey])
                                        ? new Date(state[valueKey])
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="yyyy/MM/dd HH:mm:ss"
                                timeCaption="time"
                                dateFormat="yyyy/MM/dd HH:mm:ss"
                                timeFormat="HH:mm:ss"
                                showTimeSelect
                                timeIntervals={timeIntervals}
                                minDate={minDate ? minDate : undefined}
                                maxDate={maxDate ? maxDate : undefined}
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.DATE_HOURS_MINUTES:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center justify-content-between`}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ maxWidth: isColumn ? '30%' : 'auto' }}
                            className={`label-main mt-1 text-capital pe-2 `}
                        >
                            <span className="label-main-wrapper p-relative">
                                {getLabel(messageKey)}:{' '}
                                {isRequired && (
                                    <span className="app-color-text label-required-mark">
                                        *
                                    </span>
                                )}
                            </span>
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    Date.parse(state?.[valueKey])
                                        ? new Date(
                                              Date.parse(state?.[valueKey])
                                          )
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="yyyy/MM/dd HH:mm"
                                timeCaption="time"
                                dateFormat="yyyy/MM/dd HH:mm"
                                timeFormat="HH:mm"
                                showTimeSelect
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.DATE_AND_HOURS:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center`}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            <span className="label-main-wrapper p-relative">
                                {getLabel(messageKey)}:{' '}
                                {isRequired && (
                                    <span className="app-color-text label-required-mark">
                                        *
                                    </span>
                                )}
                            </span>
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    moment(
                                        state?.[valueKey],
                                        'yyyy-MM-dd HH'
                                    ).isValid()
                                        ? new Date(
                                              moment(
                                                  state[valueKey],
                                                  'yyyy-MM-dd HH'
                                              ) as unknown as Date
                                          )
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="yyyy-MM-dd HH"
                                timeCaption="time"
                                dateFormat="yyyy-MM-dd HH"
                                timeFormat="HH:mm"
                                showTimeSelect
                                timeIntervals={60}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.FULL_TIME:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center`}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            <span className="label-main-wrapper p-relative">
                                {getLabel(messageKey)}:{' '}
                                {isRequired && (
                                    <span className="app-color-text label-required-mark">
                                        *
                                    </span>
                                )}
                            </span>
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    Date.parse(state?.[valueKey])
                                        ? new Date(state?.[valueKey])
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="HH:mm:ss.SSS"
                                timeCaption="time"
                                dateFormat="HH:mm:ss.SSS"
                                timeFormat="HH:mm:ss.SSS"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.HOURS_MINUTES_SECONDS:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center `}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control "
                                selected={
                                    moment(
                                        state?.[valueKey],
                                        'HH:mm:ss'
                                    ).isValid()
                                        ? new Date(
                                              moment(
                                                  state[valueKey],
                                                  'HH:mm:ss'
                                              ) as unknown as Date
                                          )
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="HH:mm:ss"
                                timeCaption="time"
                                dateFormat="HH:mm:ss"
                                timeFormat="HH:mm:ss"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.HOURS_MINUTES:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center `}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    moment(state?.[valueKey], 'HH:mm').isValid()
                                        ? new Date(
                                              moment(
                                                  state[valueKey],
                                                  'HH:mm'
                                              ) as unknown as Date
                                          )
                                        : undefined
                                }
                                placeholderText="HH:mm"
                                onChange={onChange}
                                timeCaption="time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        case TimeFormats.HOURS:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center `}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                locale={intl.locale}
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    moment(state?.[valueKey], 'HH').isValid()
                                        ? new Date(
                                              moment(
                                                  state[valueKey],
                                                  'HH'
                                              ) as unknown as Date
                                          )
                                        : undefined
                                }
                                onChange={onChange}
                                timeCaption="time"
                                placeholderText="HH"
                                dateFormat="HH"
                                timeFormat="HH"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
        default:
            return (
                <Form.Group
                    style={{ maxWidth: '600px' }}
                    className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center `}
                >
                    {!isLabelHidden ? (
                        <Form.Label
                            style={{ width: '30%' }}
                            className={`label-main mt-1 me-2 text-capital`}
                        >
                            {getLabel(messageKey)}:{' '}
                            {isRequired && (
                                <span className="app-color-text">*</span>
                            )}
                        </Form.Label>
                    ) : null}
                    <span
                        style={{ width: '70%' }}
                        className={`d-flex ${
                            !isColumn ? 'flex-1' : ''
                        } p-relative`}
                    >
                        <span className="d-flex align-items-center flex-column w-100">
                            <DatePicker
                                disabled={disabled ?? false}
                                className="form-control"
                                selected={
                                    Date.parse(state?.[valueKey])
                                        ? new Date(state[valueKey])
                                        : undefined
                                }
                                onChange={onChange}
                                placeholderText="yyyy/MM/dd"
                                dateFormat="yyyy/MM/dd"
                                timeIntervals={timeIntervals}
                                minDate={
                                    minDate ? new Date(minDate) : undefined
                                }
                                maxDate={
                                    maxDate ? new Date(maxDate) : undefined
                                }
                            />
                        </span>
                        {description && (
                            <PromptBtn
                                className="mt-1 input-tooltip"
                                prompt={description ?? ''}
                            />
                        )}
                    </span>
                </Form.Group>
            )
    }
}

export default DateTimePicker
