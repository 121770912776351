import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { buildControlsExt, passwordInput } from '../../inputs/controls'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ISecretProps } from './types'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { initInputData } from '../../../utils/InitInputData'
import { secretInputErrors } from '../../../utils/validationErrors/secretInputErrors'

const Secret: FC<ISecretProps> = ({
    data = {},
    dataSchema,
    name,
    wrapper,
    dataPath,
}) => {
    const dispatch = useDispatch()
    const [path] = useState<string[]>([...(dataPath || []), name])
    const [checkPath, setCheckPath] = useState<string[]>([])
    const { isChecked } = useTypedSelector((state) => state.configurations)

    const {
        is_required: isRequired,
        name: inputLabel,
        type,
        description: tooltipText,
    } = dataSchema

    const validationCheck = inputValidationCheck(type, dataSchema)
    const validationSchema = inputValidationCheck(type, dataSchema)
    const guid: string = path[0]

    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isValid,
    ] = useFormValidation(data, { [name]: validationSchema })

    const label: string = inputLabel || getLabel(name)
    const errorKey: string = path.join('')
    const isDataEmpty: number = Object.keys(data || {}).length
    const isUndefined: boolean = typeof data?.[name] === 'undefined'
    const className: string = !wrapper ? 'w-50' : ''
    const errMsg = secretInputErrors(inputState?.[name], dataSchema)
    const isEmpty: boolean = !data || !isDataEmpty || isUndefined
    const defaultValue = sessionStorage.getItem(errorKey)

    sessionStorage.setItem(errorKey, data?.[name])

    useEffect(() => {
        if (
            isChecked[guid] &&
            dataPath?.length &&
            inputState[name] !== defaultValue
        ) {
            isValid()
        }
    }, [isChecked]) // eslint-disable-line

    useEffect(() => {
        if (data && dataPath?.length) {
            setInputState(data)
        }
    }, [data]) // eslint-disable-line

    const PasswordInput = buildControlsExt(
        [
            passwordInput({
                name,
                tooltipText,
                label,
                className,
                isColumn: true,
                errMsg,
                validationSchema,
                isRequired,
                inputType: 'secret',
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    useEffect(() => {
        if (dataPath?.length) {
            setCheckPath(dataPath)
            initInputData(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    isRequired,
                    guid,
                    errorKey,
                    data,
                    setInputState,
                },
                dispatch
            )
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (dataPath?.length && inputState[name] !== defaultValue) {
            inputDataDispatcher(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    data,
                    inputState,
                    validationCheck,
                    guid,
                    errorKey,
                    isUndefined,
                    checkPath,
                    type,
                },
                dispatch
            )
        }
    }, [inputState?.[name], touched[name], isChecked[guid]]) // eslint-disable-line

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{PasswordInput}</div>
}

export default Secret
