import React, { FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'

const InformModal: FC = () => {
    const dispatch = useAppDispatch()
    const { data } = useTypedSelector((state) => state.modal)

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    return (
        <Modal
            backdrop="static"
            show={true}
        >
            {data.title && (
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage
                            id={data.title}
                            tagName="span"
                        />
                    </Modal.Title>
                </Modal.Header>
            )}
            {data.message && (
                <Modal.Body>
                    <FormattedMessage
                        id={data.message}
                        tagName="p"
                        values={data.values}
                    />
                </Modal.Body>
            )}
            <Form>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="app-btn-main app-btn-apply"
                        type="button"
                        onClick={handleClose}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default InformModal
