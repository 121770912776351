import { ILogsReducer, LogsActions } from '../../types/logs'

const initialState: ILogsReducer = {
    logs: [],
}

export const logsReducer = (
    state = initialState,
    action: { type: string; payload: any }
): ILogsReducer => {

    switch (action.type) {

        case LogsActions.FETCH_LOGS_LIST_SUCCESS: {
            return {
                ...state,
                logs: action.payload
            }
        }

        case LogsActions.DOWNLOAD_LOG_SUCCESS: {
            return { ...state }
        }

        default: {
            return state
        }
    }
}
