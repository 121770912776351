import axios from 'axios'
import { Dispatch } from 'redux'
import { ConfigurationsActionType } from '../../types/configurations'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { apiVersion } from '../../versions'
import { getHeaders } from './http'

const API = `/api/${apiVersion}/schema_and_configuration`

export const fetchProduct = (guid: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ConfigurationsActionType.FETCH_CONFIGURATION })
      const { data } = await axios.get(`${API}/${guid}`, {
        headers: getHeaders(),
      })
      dispatch({
        type: ConfigurationsActionType.FETCH_CONFIGURATION_SUCCESS,
        payload: { guid, data },
      })
    }
    catch (e: any) {
      alertErrorMessage(e);
      // throwErrorMessage(e.response?.data?.message, e.response?.status)

      dispatch({
        type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR,
        payload: 'Fetching error',
      })
    }
  }
}

export const resetProduct = (guid: string): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: ConfigurationsActionType.CONFIGURATION_RESET })
      const { data } = await axios.get(`${API}/${guid}`, {
        headers: getHeaders(),
      })
      dispatch({
        type: ConfigurationsActionType.CONFIGURATION_RESET_SUCCESS,
        payload: { guid, data },
      })
    }
    catch (e: any) {
      alertErrorMessage(e);
      // throwErrorMessage(e.response?.data?.message, e.response?.status)

      dispatch({
        type: ConfigurationsActionType.FETCH_CONFIGURATION_ERROR,
        payload: 'Fetching error',
      })
    }
  }
}

export const applyProductChanges = (
  guid: string,
  configuration: any,
  isTesting?: boolean
): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      await axios.post(
        `${API}/${guid}`,
        { ...configuration },
        { headers: getHeaders() }
      )

      throwSuccessMessage(`Success!`)

      dispatch({
        type: ConfigurationsActionType.APPLY_CONFIGURATION_SUCCESS,
        payload: { configuration, guid },
      })

      dispatch(fetchProduct(guid))
    }
    catch (e: any) {
      if (isTesting) {
        sessionStorage.removeItem('isTesting')
      }

      alertErrorMessage(e);
    }
  }
}

export const setConfigurationInValid = (
  guid: string,
  tabName: string,
  name?: string
): any => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ConfigurationsActionType.SET_INVALID_CONFIGURATION,
      payload: { guid, name, tabName },
    })
  }
}

export const setConfigurationValid = (
  guid: string,
  tabName: string,
  name?: string
): any => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ConfigurationsActionType.SET_VALID_CONFIGURATION,
      payload: { guid, name, tabName },
    })
  }
}
