import React, { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { hideModal } from '../../../store/actions/modalActions'
import { Body } from './components/Body'
import { Footer } from './components/Footer'
import { initialState } from './types'
import { fetchLogs, prepareLogs } from '../../../store/actions/logsActions'
import {
    convertDatesToRenderFormat,
    convertDatesToSendFormat,
    IRenderPeriod,
    Period,
} from './utils'

export const LogsModal: FC = () => {
    const dispatch = useAppDispatch()
    const { data } = useTypedSelector((store) => store.modal)
    const { logs } = useTypedSelector((store) => store.logs)
    const [logsState, setLogsState] = useFormValidation(initialState, {})

    useEffect(() => {
        dispatch(fetchLogs())
        const interval = setInterval(() => dispatch(fetchLogs()), 30000)
        return () => clearInterval(interval)
    }, [])

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleOnSubmit = (): void => {
        const datesToSend: Period = convertDatesToSendFormat(logsState)
        const datesToRender: IRenderPeriod =
            convertDatesToRenderFormat(logsState)
        dispatch(prepareLogs(data.guid, datesToRender, datesToSend))
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="ps-4 pe-4"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage id="modals.logs.title" />
                    </Modal.Title>
                </Modal.Header>
                <div className="ps-4 pe-4">
                    <Body
                        logsState={logsState}
                        setLogsState={setLogsState}
                    />
                </div>
                <div>
                    <Footer handleOnSubmit={handleOnSubmit} />
                </div>
            </Modal>
        </>
    )
}
