import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { flagActions } from '../../../store/actions/modelsActions/control'
import { ConfigurationsActionType } from '../../../types/configurations'
import { getLabel } from '../../../validators'
import { buildControlsExt, checkboxInput } from '../../inputs/controls'
import { IFlagProps } from './types'

const Flag: FC<IFlagProps> = ({
  data = {},
  dataSchema = {},
  name,
  wrapper,
  dataPath,
  isViewOnly,
}) => {
  const dispatch = useAppDispatch()
  const { description, is_nullable: isNullable, name: inputLabel } = dataSchema
  const [inputState, setInputState, touched] = useFormValidation(data || {}, {})
  const [path] = useState([...(dataPath || []), name])
  const [isChanged, setIsChanged] = useState(inputState?.[name])
  const label: string = inputLabel || getLabel(name)

  const FlagInput = buildControlsExt(
    [checkboxInput(name, description, label, '', isViewOnly, '', isNullable)],
    inputState,
    setInputState,
    '',
    touched
  )

  useEffect(() => {
    if (dataPath?.length) {
      if (
        !data ||
        !Object.keys(data).length ||
        typeof data?.[name] === 'undefined'
      ) {
        dispatch({
          type: ConfigurationsActionType.INIT_EMPTY_DATA,
          payload: { schema: dataSchema, name, path },
        })
      }
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (data && dataPath?.length) {
      setInputState(data)
    }
  }, [data]) // eslint-disable-line

  useEffect(() => {
    if (isChanged !== inputState?.[name] && dataPath?.length) {
      setIsChanged(inputState?.[name])
      dispatch(flagActions.modify(inputState?.[name], path, data))
      // dispatch({
      //   type: ConfigurationsActionType.FLAG_MODIFY,
      //   payload: { componentState: inputState?.[name], path, data },
      // })
    }
  }, [inputState]) // eslint-disable-line

  return <div className={`${!wrapper ? 'w-100' : ''}`}>{FlagInput}</div>
}

export default Flag
