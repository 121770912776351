import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { IFooter } from '../types'
import { Downloads } from './Downloads'

export const Footer: FC<IFooter> = ({ handleOnSubmit }) => {
    return (
        <div className="ps-4 pe-4 mt-1 pb-3">
            <div className="d-flex justify-content-end w-100 pb-2">
                <button
                    type="button"
                    className="app-btn-main app-btn-apply"
                    onClick={handleOnSubmit}
                >
                    <FormattedMessage id="buttons.prepare" />
                </button>
            </div>
            <Downloads />
        </div>
    )
}
