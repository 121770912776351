import React, { FC } from 'react'
import * as yup from 'yup'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import AppButton from '../buttons/AppButton/AppButton'

const schema = yup.string().required()

export interface IProductNameInput {
  name: string
  setProductName: any
  handleOnSave: any
  handleOnCancel: any
  className?: string
  buttonsClassName?: string
  feedbackClassName?: string
  size?: 'sm' | 'lg'
}

const ProductNameInput: FC<IProductNameInput> = ({
  name,
  setProductName,
  handleOnSave,
  handleOnCancel,
  buttonsClassName,
  feedbackClassName,
  size,
  className,
}) => {
  const isValid = schema.isValidSync(name)
  const errMsg = 'errors.validation.minSize-1'
  const iconSize = size === 'sm' ? 'icon-small' : 'icon-main'

  const handleOnChange = (e: any) => {
    setProductName(e.target.value)
  }

  const handleOnEnter = (evt: any) => {
    if (evt.which === 13 && isValid) {
      handleOnSave()
    }
  }

  return (
    <>
      <span className={`${className} d-flex flex-column w-100 me-1 p-relative`}>
        <Form.Control
          onKeyPress={handleOnEnter}
          className={`product-name-input product-name-input-${size}`}
          type="text"
          placeholder="Product name..."
          value={name}
          onChange={handleOnChange}
          isInvalid={!isValid}
        />
        <Form.Control.Feedback className={feedbackClassName} type="invalid">
          <FormattedMessage id={errMsg} tagName="span" />
        </Form.Control.Feedback>
      </span>
      <span className={`${className} me-1 d-flex`}>
        <AppButton
          variant="save"
          onClick={handleOnSave}
          disabled={!isValid}
          className={`p-0 navi-blue ${iconSize} ${buttonsClassName}`}
          tooltip="Save changes"
        />
        <AppButton
          variant="close"
          onClick={handleOnCancel}
          className={`p-0 ${
            iconSize === 'icon-main' ? 'ms-1' : 'me-1'
          } navi-blue ${iconSize} ${buttonsClassName}`}
          tooltip="Cancel changes"
        />
      </span>
    </>
  )
}

export default ProductNameInput
