import React, { FC, useRef, useState } from 'react'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Overlay, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import style from './Styles/index.module.scss'
import { IItem } from './types'

interface IAppDropDown {
  itemsList: IItem[]
}

export const AppDropDown: FC<IAppDropDown> = ({ itemsList }) => {
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState(null)
  const ref = useRef(null)
  const handleClick = (event: any) => {
    setShow(!show)
    setTarget(event.target)
  }
  const handleBlur = (event: any) => {
    setTimeout(() => {
      setShow(false)
      setTarget(event.target)
    }, 100)
  }

  return (
    <div ref={ref}>
      <button
        type="button"
        className={style.toggleButton}
        onBlur={handleBlur}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faEllipsisH} />
      </button>
      <Overlay show={show} target={target} placement="auto" container={ref}>
        <Popover className={style.popoverContainer} id="popover-basic">
          <Popover.Body className={style.popoverBody}>
            {itemsList.map((item: IItem) => (
              <button
                type="button"
                className={`${style.itemButton} ${item.className}`}
                onClick={item.action}
                key={item.name}
              >
                <FontAwesomeIcon className="me-2" icon={item.icon} />
                <FormattedMessage id={item.name} />
              </button>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  )
}
