import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import PromptBtn from '../buttons/PromptBtn/PromptBtn'
import { ISelectItems } from './types'

const SelectInput: React.FC<ISelectItems> = ({
  className,
  label,
  name,
  options,
  state,
  setState,
  isDisabled,
  style,
  flag,
  tooltipText,
  selected,
  setTouched,
  touched,
  isColumn,
  tooltipPosition,
  disabledOptions = [],
}) => {
  useEffect(() => {
    if (selected) {
      setState({ ...state, [name]: selected })
    }
  }, []) // eslint-disable-line

  const optionComponents = React.useMemo(
    () =>
      options.map((option: string) => {
        if (disabledOptions.includes(option)) {
          return (
            <option
              key={option}
              value={option}
              className={flag ? 'first-hiden' : ''}
              disabled
            >
              {option.replace('_', ' ')}
            </option>
          )
        }
        return (
          <option
            key={option}
            value={option}
            className={flag ? 'first-hiden' : ''}
          >
            {option.replace('_', ' ')}
          </option>
        )
      }),
    [options] // eslint-disable-line
  )

  const handleChange = (event: any): void => {
    setTouched && setTouched({ ...touched, [name]: true })
    setState({ ...state, [name]: event.target.value })
  }

  const handleBlur = (): void => {
    setTouched && setTouched({ ...touched, [name]: true })
  }

  return (
    <Form.Group
      style={{ maxWidth: '600px' }}
      className={`${className} mt-1 mb-1 d-flex flex-1 align-items-center justify-content-between`}
    >
      {label ? (
        <Form.Label
          style={{ maxWidth: isColumn ? '30%' : 'auto' }}
          className="label-main mt-1 text-capital pe-2"
        >
          {label}:
        </Form.Label>
      ) : null}
      <div
        style={{ width: '70%' }}
        className={`d-flex ${!isColumn ? 'flex-1' : ''} p-relative`}
      >
        <span className="d-flex align-items-center w-100">
          <Form.Control
            onBlur={handleBlur}
            as="select"
            name={name}
            value={state?.[name] ?? ''}
            onChange={handleChange}
            disabled={!!isDisabled}
            style={style}
          >
            {optionComponents}
          </Form.Control>
          {tooltipText && (
            <PromptBtn position={tooltipPosition} prompt={tooltipText ?? ''} />
          )}
        </span>
      </div>
    </Form.Group>
  )
}

export default SelectInput
