import { inputTypes } from './types'
import * as yup from 'yup'
import { getIntegerSchema } from './utils/getIntegerSchema'
import { getDurationSchema } from './utils/getDurationSchema'
import { getNumberSchema } from './utils/getNumberSchema'
import { getDoubleSchema } from './utils/getDoubleSchema'
import { getStringSchema } from './utils/getStringSchema'
import { getSecretSchema } from './utils/getSecretSchema'
import { getBlockArraySchema } from './utils/getBlockArraySchema'

export function inputValidationCheck(type: string, schema?: any, value?: any) {
  let inputString: any = yup.string()
  let blockArray: any = yup.array()

  switch (type) {
    case inputTypes.INTEGER:
      const integerSchema = getIntegerSchema(inputString, schema)
      return integerSchema

    case inputTypes.DURATION:
      const durationSchema = getDurationSchema(inputString, schema)
      return durationSchema

    case inputTypes.NUMBER:
      const numberSchema = getNumberSchema(inputString, schema)
      return numberSchema

    case inputTypes.DOUBLE:
      const doubleSchema = getDoubleSchema(inputString, schema, value)
      return doubleSchema

    case inputTypes.STRING:
      const stringSchema = getStringSchema(inputString, schema)
      return stringSchema

    case inputTypes.SECRET:
      const secretSchema = getSecretSchema(inputString, schema)
      return secretSchema

    case inputTypes.BLOCK_ARRAY:
      const blockArraySchema = getBlockArraySchema(blockArray, schema)
      return blockArraySchema

    default:
      if (schema?.is_required) {
        inputString = inputString.required()
      }
      return inputString
  }
}
