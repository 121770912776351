import React, { FC } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { NavLink } from 'react-router-dom'
import { RoutesPaths } from '../../router'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { FormattedMessage } from 'react-intl'

const ErrorModal: FC = () => {
    const dispatch = useAppDispatch()
    const { data } = useTypedSelector((state) => state.modal)

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleOnRetry = (): void => {
        dispatch(data.request())
        handleClose()
    }

    return (
        <Modal
            show={true}
            backdrop="static"
            centered
        >
            <Modal.Header>
                <Modal.Title>{data.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{data.message}</Modal.Body>
            <Form>
                <Modal.Footer>
                    <button
                        type="button"
                        className="app-btn-main app-btn-apply"
                        onClick={handleOnRetry}
                    >
                        <FormattedMessage id="buttons.retry" />
                    </button>
                    {data.onCancel === 'cancel' ? (
                        <button
                            className="app-btn-cancel btn-primary app-btn-main me-2"
                            type="button"
                            onClick={handleClose}
                        >
                            <FormattedMessage id="buttons.cancel" />
                        </button>
                    ) : (
                        <NavLink
                            to={RoutesPaths.SUMMARY}
                            onClick={handleClose}
                            className="nav-link "
                            activeClassName="active"
                        >
                            <FormattedMessage id="buttons.goToSummary" />
                        </NavLink>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ErrorModal
