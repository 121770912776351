import { IProduct } from '../types/product'

export enum ProductStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    IDLE = 'Idle',
}

export class ProductEntity implements IProduct {
  status: ProductStatus.ACTIVE | ProductStatus.INACTIVE | ProductStatus.IDLE
  guid: string
  name: string
  version: string
  ip: string
  path: string
  display_name?: string

  constructor(product: any) {
    this.status = product.status ?? ''
    this.guid = product.guid ?? ''
    this.name = product.name ?? ''
    this.version = product.version ?? ''
    this.ip = product.ip ?? ''
    this.path = product.path ?? ''
    this.display_name = product.display_name || undefined
  }
}
