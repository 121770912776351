import React, { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { hideModal } from '../../store/actions/modalActions'
import { deleteProductsGroup } from '../../store/actions/products/groupsActions'
import { deleteProducts } from '../../store/actions/products/productsActions'
import { InnerModal } from './InnerModal'

const DeleteProductsGroupModal: FC = () => {
    const dispatch = useAppDispatch()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDisbandModal, setShowDisbandModal] = useState(false)
    const { data } = useTypedSelector((state) => state.modal)

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const deleteGroupHandle = (): void => {
        setShowDeleteModal(true)
    }

    const disbandGroupHandle = (): void => {
        setShowDisbandModal(true)
    }

    const disbandGroupAction = (): void => {
        setShowDisbandModal(false)
        dispatch(deleteProductsGroup(data.groupId))
        dispatch(hideModal())
    }

    const deleteGroupAction = (): void => {
        setShowDeleteModal(false)
        if (data.products.length) {
            dispatch(deleteProducts(data.products))
        }
        dispatch(deleteProductsGroup(data.groupId))
        dispatch(hideModal())
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                show={true}
                onHide={handleClose}
                centered
            >
                <Modal.Header
                    className="px-4"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage
                            id={'modals.deleteGroup.deleteOrDisband'}
                        />
                    </Modal.Title>
                </Modal.Header>
                <div className="px-4 py-4">
                    <FormattedMessage
                        id={'modals.deleteGroup.deleteOrDisbandBodyText'}
                    />
                </div>
                <Modal.Footer className="ps-4 pe-4">
                    <button
                        type="button"
                        className="app-btn-cancel btn-primary app-btn-main ms-auto me-2"
                        onClick={disbandGroupHandle}
                    >
                        <FormattedMessage id="modals.editGroup.disband" />
                    </button>
                    <button
                        type="button"
                        className="app-btn-delete btn-primary app-btn-main "
                        onClick={deleteGroupHandle}
                    >
                        <FormattedMessage id="modals.editGroup.delete" />
                    </button>
                </Modal.Footer>
            </Modal>
            <InnerModal
                setShow={setShowDisbandModal}
                show={showDisbandModal}
                titleMessage="modals.editGroup.disband"
                bodyMessage="modals.editGroup.disbandGroupConfirmation"
                btnCancelMsg="buttons.cancel"
                btnApplyMsg="modals.editGroup.disband"
                onAction={disbandGroupAction}
            />
            <InnerModal
                setShow={setShowDeleteModal}
                show={showDeleteModal}
                titleMessage="modals.editGroup.deleteTheGroup"
                bodyMessage="modals.editGroup.deleteGroupConfirmation"
                btnCancelMsg="buttons.cancel"
                btnApplyMsg="modals.editGroup.deleteTheGroup"
                onAction={deleteGroupAction}
            />
        </>
    )
}

export default DeleteProductsGroupModal
