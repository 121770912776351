export const getDoubleSchema = (inputString: any, schema: any, value?: any) => {
  inputString = inputString.test('Is NaN', (val: any) => {
    if (val && val !== 'null' && val !== '0') {
      if (val.includes(',')) {
        return Number(val.replace(',', '.'))
      }
      return Number(val)
    }
    return true
  })
  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test('Is greater or equal', (val: any) => {
      if (
        !schema.is_required &&
        (val === '' || val === null || val === 'null')
      ) {
        return true
      }
      return Number(val) >= schema.greater_or_equal
    })
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test('Is greater than', (val: any) => {
      if (
        !schema.is_required &&
        (val === '' || val === null || val === 'null')
      ) {
        return true
      }
      return Number(val) > schema.greater_than
    })
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test('Is less or equal', (val: any) => {
      if (
        !schema.is_required &&
        (val === '' || val === null || val === 'null')
      ) {
        return true
      }
      return Number(val) <= schema.less_or_equal
    })
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('Is less than', (val: any) => {
      if (
        !schema.is_required &&
        (val === '' || val === null || val === 'null')
      ) {
        return true
      }
      return Number(val) <= schema.less_than
    })
  }
  if (schema.is_required) {
    inputString = inputString.required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}
