export enum inputTypes {
  STRING = 'string',
  INTEGER = 'integer',
  SECRET = 'secret',
  FLAG = 'flag',
  BOOL = 'bool',
  ENUM = 'enum',
  NUMBER = 'number',
  INT64 = 'int64',
  UINT64 = 'uint64',
  INT32 = 'int32',
  UINT32 = 'int32',
  INT16 = 'int16',
  UINT16 = 'uint16',
  TIMEZONE = 'timezone',
  TIMEZONE_DIFFERENCE = 'timezone_difference',
  DATETIME = 'datetime',
  TIME = 'time',
  DURATION = 'duration',
  DOUBLE = 'double',
  BLOCK_ARRAY = 'block_array',
}
