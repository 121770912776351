import {
  faArrowRightFromBracket,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { UserPermissions } from '../../../entity/User'
import { IItem } from '../../overlays/AppDropDown/types'

export const getItemsList = (
  permissions: string[],
  groupId: string | undefined,
  handleOnEdit: () => void,
  handleOnRemove: () => void,
  handleOnDelete: (guid: string, productName: string) => void
): IItem[] => {
  const isAdmin = permissions.includes(
    UserPermissions.CONFIGURATION_ADMINISTRATION
  )

  const deleteProduct: IItem = {
    name: 'productCard.deleteProductBtn',
    icon: faTrashAlt,
    action: handleOnDelete,
    className: 'app-color-red',
  }

  const editProduct: IItem = {
    name: 'productCard.editNametBtn',
    icon: faEdit,
    action: handleOnEdit,
  }

  const removeFromGroup: IItem = {
    name: 'productCard.removeFromGroupBtn',
    icon: faArrowRightFromBracket,
    action: handleOnRemove,
  }

  if (isAdmin) {
    if (groupId) {
      return [editProduct, removeFromGroup, deleteProduct]
    }
    return [editProduct, deleteProduct]
  }

  return [deleteProduct]
}
