import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { hideModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { Footer } from './components/Footer'
import { Groups } from './components/Groups'
import {
    getMultiSelectOptionsProductsEntries,
    getMultiSelectOptionsProductsGroups,
} from './utils'
import { getFormattedGroupData, getGroupData } from './utils/getGroupData'
import * as yup from 'yup'
import { editProductsGroup } from '../../../store/actions/products/groupsActions'

const schema = {
    name: yup.string().required().min(1),
}

export const EditGroupModal: FC = () => {
    const dispatch = useAppDispatch()

    const { modal } = useSelector((state: any) => state)
    const { data } = useTypedSelector((state) => state.modal)

    const { products, productsGroups } = useTypedSelector(
        (state) => state.products
    )

    const [groupInitialData, setInitialData] = useState(
        getGroupData(
            data?.groupId || productsGroups?.[0]?.group_id,
            productsGroups
        )
    )

    const [groupId, setGroupId] = useState(
        data?.groupId || productsGroups?.[0]?.group_id
    )

    const [groupsList, setGroupList] = useState(
        getMultiSelectOptionsProductsGroups(productsGroups)
    )

    const [groupEntries, setGroupEntries] = useState(
        getMultiSelectOptionsProductsEntries(productsGroups, groupId, products)
    )

    const [groupState, setGroupState, touched, setTouched, validationResult] =
        useFormValidation(groupInitialData, schema)

    const modalTypeChange: boolean = modal.modalType !== ModalTypes.EDIT_GROUPS_MODAL

    useEffect(() => {
        setGroupList(getMultiSelectOptionsProductsGroups(productsGroups))
        setGroupId(groupState?.group?.value || productsGroups?.[0]?.group_id)

        setGroupEntries(
            getMultiSelectOptionsProductsEntries(
                productsGroups,
                groupState?.group?.value,
                products
            )
        )

        setInitialData(getGroupData(groupState?.group?.value, productsGroups))
    }, [ modalTypeChange, groupState?.group?.value])

    useEffect(() => {
        setGroupState({
            groupId: groupId,
            group: groupsList[0],
            name: groupInitialData?.name,
            entries: groupEntries,
        })
    }, [modalTypeChange])

    useEffect(() => {
        setGroupState({
            ...groupState,
            groupId: groupId,
            name: groupInitialData?.name,
            entries: groupEntries,
        })
    }, [groupId])

    const handleClose = (): void => {
        setInitialData(
            getGroupData(
                data?.groupId || productsGroups?.[0]?.group_id,
                productsGroups
            )
        )
        dispatch(hideModal())
    }

    const handleOnApply = (): void => {
        const formattedData = getFormattedGroupData(groupState)
        dispatch(editProductsGroup(groupId, formattedData, handleClose))
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                show={true}
                onHide={handleClose}
                centered
            >
                <Modal.Header
                    className="ps-4 pe-4"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage id="summary.editGroupTitle" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ps-4 pe-4">
                    <div className="m-0">
                        <Groups
                            groupState={groupState}
                            setGroupState={setGroupState}
                            touched={touched}
                            setTouched={setTouched}
                            validationResult={validationResult}
                            groupsList={groupsList}
                            groupEntries={groupEntries}
                        />
                    </div>
                </Modal.Body>
                <Footer
                    groupId={groupId}
                    handleApply={handleOnApply}
                    groupEntries={groupEntries}
                    validationResult={validationResult}
                />
            </Modal>
        </>
    )
}
